


























































































import { Component, Inject, Vue } from "vue-property-decorator";
import FileDrop from "@/components/common/filedrop.vue";
import TasksList from "@/components/tasks/TasksList.vue";
import FileSelection from "@/components/common/FileSelection.vue";
import { BackendClient, FileDescriptor } from "ibf-backend";

@Component({
  components: {
    FileSelection,
    FileDrop,
    TasksList,
  },
})
export default class PSAtoRPP extends Vue {
  @Inject("backendClient") client!: BackendClient;

  public selectedRsVersion = null;

  mounted() {
    this.client
      .get("/rppconverter/availableRsVersions")
      .then((response) => (this.availabaleRSVersions = response.data));
  }

  private availabaleRSVersions = [];

  private selectedUserFiles: FileDescriptor[] = [];

  private selectedComputerFiles: File[] = [];

  private selectedComputerFilesChange(files: File[]) {
    this.selectedComputerFiles = files;
  }

  private launchConversion() {
    this.selectedUserFiles.forEach((fileDescriptor) =>
      this.client.taskApi.createTask({
        type: "PSAtoRPP",
        parameters: {
          file: fileDescriptor.name,
          config: { "rs-version": this.selectedRsVersion },
        },
        files: [
          {
            name: fileDescriptor.name,
            context: "USER",
            path: fileDescriptor.uri,
          },
        ],
      })
    );

    this.selectedComputerFiles.forEach((file) =>
      this.client.taskApi.createTask(
        {
          type: "PSAtoRPP",
          parameters: {
            file: file.name,
            config: { "rs-version": this.selectedRsVersion },
          },
          files: [
            {
              name: file.name,
              context: "TASK",
            },
          ],
        },
        [file]
      )
    );

    this.selectedComputerFiles = [];
    this.selectedUserFiles = [];
  }

  private get filesToConvertCount(): number {
    return this.selectedUserFiles.length + this.selectedComputerFiles.length;
  }
}
